import React from 'react'
import { Link } from 'react-router-dom'
import Art from './Art'

export default function ImageFrame(props) {
  const { title } = props.image
  return (
    <div className='image-frame'>
      <Link to={{ pathname: '/detail/' + title }}>
        <Art art={props.image} />
        <div className='flex-container row narrow'>
          <p className='image-title'>{title}</p>
        </div>
      </Link>
    </div>
  )
}
