import React from 'react'

function Chatham2024() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>CHATHAM FESTIVAL OF THE ARTS </h3>
        <ul className='event-date'>
          <li>exhibition dates: August 16, 17 & 18, 2024</li>
          <li> <a href='https://www.capecodcreativearts.org/events-1/2022/8/19/51st-annual-festival-of-the-arts-na4wr'> www.capecodcreativearts.org </a> </li>
          <li>Chase Park, Chatham, MA</li>
        </ul>
      </div>

      <div className='picture-container'>
        <img src='../../biopic/Chatham_TealVase.jpg' alt='' className='img' />
        <img src='../../biopic/Chatham_Murine.jpg' alt='' className='img' />
        <img src='../../biopic/Chatham_GlassFish.jpg' alt='' className='img' />
      </div>
    </div>
  )
}

const details = {
  component: Chatham2024,
  title: 'Chatham Festival Of The Arts',
  StartDate: 'August 16, 2024',
  EndDate: 'August 18, 2024',
  eventId: 'Chatham-festival-of-the-arts',
}

export default details