import React from 'react'

function Arlington2023() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Arlington OpenStudios</h3>
        <ul className='event-date'>
          <li> exhibition dates: november 12, 2023</li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/AOS2023.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: Arlington2023,
  title: 'Arlington Open Studios',
  StartDate: 'November 12, 2023',
  EndDate: 'November 12, 2023',
  eventId: 'arlington-openstudio-2023',
}

export default details
