import React from 'react'

function NEWSG2022() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>NEWS Gallery Show</h3>
        <ul className='event-date'>
          <li> exhibition dates: March-April 2022 </li>
          <p>
            {' '}
            <a href='https://newenglandwatercolorsociety.org/'> NEWS </a>{' '}
          </p>
        </ul>
      </div>
      <div className='picture-container'>
        <img src='../biopic/NapaV.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: NEWSG2022,
  title: 'NEWS Gallery Show',
  StartDate: 'March 1, 2022',
  EndDate: 'April 22, 2022',
  eventId: 'NEWSG2022',
}

export default details
