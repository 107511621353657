import React from 'react'

function BAF2024() {
  return (
  <div className='pageContainer'>
    <div className='event-header'>
        <h3 className='event-title'> BOSTON ARTS FESTIVAL</h3>
        <ul className='event-date'>
          <li> exhibition dates: September 7 & 8, 2024 </li>
          <li> <a href='https://thebostonartsfestival.com/'> Boston Arts Festival </a> </li>
          <li>Christopher Columbus Watefront Park, Boston, MA</li>
        </ul>
    </div>
      <div className='picture-container'>
        <img src='../biopic/BostonArts1.jpg' alt='' className='img' />
        <img src='../biopic/BostonArts2.jpg' alt='' className='img' />
        <img src='../../biopic/Chatham_GlassFish.jpg' alt='' className='img' />
    </div>
  </div>
  )
}

const details = {
  component: BAF2024,
  title: 'Boston Arts Festival',
  StartDate: 'September 7, 2024',
  EndDate: 'September 8, 2024',
  eventId: 'Boston-Arts-Festival',
}

export default details