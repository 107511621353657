import React from 'react'

function BeaconHillArtWalk2021() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Beacon Hill Art Walk</h3>
        <p className='event-date'>Sept 2021</p>
      </div>
      <div className='picture-container'>
        <img src='../biopic/BeaconHillArtWalk_IMG_6931.jpg' alt='' className={'img'} />
        <img src='../biopic/BeaconHillArtWalk_IMG_6932.jpg' alt='' className={'img'} />
        <img src='../biopic/BeaconHillArtWalk_IMG_6939.jpg' alt='' className={'img'} />
        <img src='../biopic/BeaconHillArtWalk_IMG_6942.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: BeaconHillArtWalk2021,
  title: 'Beacon Hill Art Walk',
  StartDate: 'September 1, 2021',
  EndDate: 'September 30, 2021',
  eventId: 'beacon-hill-art-walk-2021',
}

export default details
