import React from 'react'
import events from '../Events'
import { useParams } from 'react-router-dom'

export default function ArtShowEventPage() {
  const { eventId } = useParams()

  const event = events.find(s => s.eventId === eventId)
  const Component = event.component

  return (
    <div className='pageContainer'>
      <Component />
    </div>
  )
}
