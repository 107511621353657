import React, { useRef } from 'react'
import { useLocation, Link } from 'react-router-dom'

export default function NavigationThumbnail(props) {
  const { title, image, category, attributes } = props

  const location = useLocation()
  const query = useRef(new URLSearchParams(location.search))

  const createLinkTarget = (category, attributes) => {
    if (location.search === '?' + query.current.toString()) return
    category && query.current.set('category', category)
    attributes && query.current.set('attributes', attributes)
    return query.current.toString()
  }

  const createTargetString = () => {
    const targetPage = createLinkTarget(category, attributes)
    return `${category}?${targetPage}`
  }

  return (
    <div className={'thumbnail-wrapper'}>
      <img src={image} alt='' className={'thumbnail-image'} />
      <div className={'overlay'}>
        <div className={'overlay-shade'}></div>
        <Link to={createTargetString()}>
          <h3 className={'thumbnail-title'}>{title}</h3>
        </Link>
      </div>
    </div>
  )
}
