import React from 'react'

function Watertown2021() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Watertown Arts Festival</h3>
        <p className='event-date'>August 2021</p>
      </div>
      <div className='picture-container'>
        <img src='../biopic/Watertown_06_MaryannHondo_02.jpg' alt='' className={'img'} />
        <img src='../biopic/06_MaryannHondo_01.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: Watertown2021,
  title: 'Watertown Arts Festival',
  StartDate: 'August 1, 2021',
  EndDate: 'August 31, 2021',
  eventId: 'watertown-arts-2021',
}

export default details
