import React from 'react'

function Chatham2022() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Chathamgton Festival of the Arts</h3>
        <ul className='event-date'>
          <li> exhibition dates: august 19, 20 21, 2022 </li>
          <li>
            <a href='http://www.capecodcreativearts.org/exhibitions-1/2022/8/19/51st-annual-festival-of-the-arts'>
              {' '}
              51st Festival of the Arts
            </a>
          </li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/pemaquid.jpg' alt='' className={'img'} />
        <img src='../biopic/artdeco-Chatham.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: Chatham2022,
  title: 'Chatham Festival of Arts',
  StartDate: 'August 19, 2022',
  EndDate: 'August 21, 2022',
  eventId: 'Chatham2022',
}

export default details
