import React from 'react'

function Arlington2021() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Arlington REAL</h3>
        <ul className='event-date'>
          <li> exhibition dates: october 21, 2021 - january 14, 2022 </li>
          <li> opening reception: thursday, october 21, 6-7:30pm </li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/pemaquid.jpg' alt='' className={'img'} />
        <img src='../biopic/SantoStefano.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: Arlington2021,
  title: 'Arlington REAL',
  StartDate: 'October 21, 2021',
  EndDate: 'January 14, 2022',
  eventId: 'arlington-real-2021',
}

export default details
