import React from 'react'

function createPurchaseLink(art) {
  const artUrl = encodeURI(`${window.location.origin}/detail/${art.title}`)
  const to = 'mhondoart@gmail.com'
  const subject = `Purchase Inquiry for "${art.title}"`
  const body = `Hello, I am interested in purchasing your piece "${art.title}"!\n\n${artUrl}`

  const mailto = `mailto:${encodeURIComponent(to)}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`

  return mailto
}

function createContactLink() {
  return 'mailto:mhondoart@gmail.com'
}

export default function EmailLink({ art, children, ...rest }) {
  const link = art ? createPurchaseLink(art) : createContactLink()
  return (
    <a href={link} target='_blank' rel='noopener noreferrer' {...rest}>
      {children}
    </a>
  )
}
