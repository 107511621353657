import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

import * as serviceWorker from './serviceWorker'
// import ThumbnailContainer from './ThumbnailContainer';

ReactDOM.render(<App />, document.getElementById('root'))

// ReactDOM.render(<ThumbnailContainer />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister()
