import React from 'react'
import EmailLink from '../Components/EmailLink'
import './Nav.css'

export const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-text'>
        <h4 className='reverse-text site-copy'>Maryann Hondo</h4>
        <p className='reverse-text site-copy copywrite'>&copy; 2020 Maryann Hondo</p>
        <EmailLink style={{ textDecoration: 'none' }}>
          <p className='reverse-text site-copy email'>mhondoart@gmail.com</p>
        </EmailLink>
      </div>
    </div>
  )
}
