import React from 'react'

function TrueBistro2023() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>TrueBistro 2023</h3>
        <div className='event-date'>June 19, 2023</div>
        <a href='http://www.truebistroboston.com'>www.truebistroboston.com</a>
        <p>
          1153 Broadway
          <br />
          Somerville, MA 02144
          <br />
          617-627-9000
        </p>
      </div>
      <div className='image-container'>
        <img src='../biopic/TrueBistroShowPicture_web.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: TrueBistro2023,
  title: 'True Bistro 2023',
  StartDate: 'June 19, 2023',
  EndDate: 'August 1, 2023',
  eventId: 'true-bistro-2023',
}

export default details
