import React, { useLayoutEffect, useRef } from 'react'
import classNames from 'classnames'
import useResizeObserver from 'use-resize-observer'
import emptyImage from '../Assets/emptyImage.png'

/**
 * `img` element wrapper for displaying processed art images. The image is lazy
 * loaded. The height is set based on the width of the element, which is set to
 * match its parent.
 *
 * The `art` prop should be an item from './Assets/artList.json'.
 */
export default function Art({ art, className, style, ...rest }) {
  const images = sortByAscendingWidth(art.images)
  const srcSet = generateSrcSet(images)
  const ref = useMaintainAspectRatio(images)

  return (
    <img
      ref={ref}
      // Use an empty image to avoid rendering the broken image icon. Images
      // that haven't yet loaded use the accent color as the background
      src={emptyImage}
      style={{ backgroundColor: art.accentColors.light, ...style }}
      data-srcset={srcSet}
      data-sizes='auto'
      className={classNames('lazyload', 'image', className)}
      alt={art.title}
      {...rest}
    />
  )
}

export function generateSrcSet(images) {
  return images.map(i => `${artPath(i)} ${i.width}w`).join(', ')
}

function useMaintainAspectRatio(images) {
  const ref = useRef()
  const { width } = useResizeObserver({ ref })
  const aspectRatio = Math.max(0.25, images[0].width / images[0].height)

  useLayoutEffect(() => {
    ref.current.height = ref.current.width / aspectRatio
  }, [width, aspectRatio])

  return ref
}

function sortByAscendingWidth(images) {
  return Object.values(images).sort(({ width: w1 }, { width: w2 }) => w1 - w2)
}

function artPath(image) {
  return `${process.env.PUBLIC_URL}/art/${image.path}`
}
