import React from 'react'
import { useLocation } from 'react-router-dom'
import artList from '../Assets/artList.json'
import Gallery from '../Components/Gallery'
import { Footer } from '../Navigation/Footer'
import { Nav } from '../Navigation/Nav'
import '../Navigation/Nav.css'

export const Category = props => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const { match } = props

  const { category } = match.params
  const attributes = query.get('attributes')

  return (
    <div className='page-wrapper painting' style={{ display: 'flex', flexDirection: 'column' }}>
      <div className='nav-wrapper' style={{ flex: 1 }}>
        <Nav />
      </div>
      <Gallery category={category} attributes={attributes} artList={artList} />
      <Footer />
    </div>
  )
}
