import watertown2021 from './Watertown2021Event'
import arlington2021 from './Arlington2021Event'
import beaconhillartwalk2021 from './BeaconHIllArtWalk'
import beaconhill2022 from './BeaconHill2022'
import news2022 from './NEWatercolorSociety2022'
import newsgallery2022 from './NEWSGallery2022'
import bostonarts2022 from './BostonArts2022'
import chatham2022 from './Chatham2022'
import arlington2022 from './Arlington2022'
import { seasonsOfLight2023, trueBistro2023, chatham2023, arlington2023 } from './Events2023'
import { chatham2024,baf2024} from './Events2024'

const events = [
  watertown2021,
  arlington2021,
  beaconhillartwalk2021,
  news2022,
  beaconhill2022,
  newsgallery2022,
  bostonarts2022,
  arlington2022,
  chatham2022,
  seasonsOfLight2023,
  trueBistro2023,
  chatham2023,
  arlington2023,
  chatham2024,
  baf2024
]

export default events
