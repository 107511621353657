import React from 'react'
import { Footer } from '../Navigation/Footer'
import Marquee from '../Components/Marquee'
import { Nav } from '../Navigation/Nav'
import ThumbnailContainer from '../Navigation/ThumbnailContainer'

export const Home = () => {
  return (
    <div className='page-wrapper'>
      <div className='nav-wrapper'>
        <Nav />
      </div>
      <Marquee />
      <ThumbnailContainer />
      <Footer />
    </div>
  )
}
