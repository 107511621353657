import React from 'react'
import './../App.css'

import { Link } from 'react-router-dom'

export const TopicsMenu = props => {
  const { title, category, attributes } = props
  return (
    <div className='topic-menu'>
      <ul className='dropdown'>
        <li className='menu-header'>
          <Link
            to={{
              pathname: '/' + category,
            }}
          >
            <p className='nav-topic'>{title}</p>
          </Link>
        </li>
        {attributes.map((attr, i) => {
          const formatedAttr = attr
            .split('')
            .filter(l => l !== ' ')
            .join('')
          return (
            <li className='dropdown' key={i}>
              <Link
                to={{
                  pathname: '/' + category,
                  search: `?attributes=${formatedAttr}`,
                }}
              >
                {attr}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
