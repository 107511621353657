import React from 'react'
import EmailLink from '../Components/EmailLink'
import { Nav } from '../Navigation/Nav'
import '../Navigation/Nav.css'
import { Link } from 'react-router-dom'
import maryannPainting from '../paintingInGreece.jpg'
import maryannGlass from '../Maryann-glass-studio.jpg'
import events from './../Events'

function ShowItem({ show }) {
  return (
    <li>
      <Link to={{ pathname: `/shows/${show.eventId}` }}>
        <p>
          {show.title} | <span className='sub-head'>{show.StartDate}</span>
        </p>
      </Link>
    </li>
  )
}

function isUpcoming(date) {
  const now = Date.parse(new Date())
  const showDate = Date.parse(date)
  return showDate > now
}

export const Contact = props => {

  events.sort((a, b) => Date.parse(a.StartDate) - Date.parse(b.StartDate))
  console.log(events.map(e => e.StartDate))
  const upcomingShows = events.filter(s => isUpcoming(s.StartDate))
  const currentShows = events.filter(s => !isUpcoming(s.StartDate) && isUpcoming(s.EndDate))
  const pastShows = events.filter(s => !isUpcoming(s.EndDate))

  return (
    <div className='page-wrapper contact-page'>
      <div className='nav-wrapper' style={{ flex: 1 }}>
        <Nav />
      </div>
      <div className='contact-detail'>
        <h5>Email</h5>
        <ul>
          <p>
            <EmailLink>mhondoart@gmail.com</EmailLink>
          </p>
        </ul>
        <div>
         <h5> <span> <topic-menu> {'Artwork for Sale'} </topic-menu> </span> </h5>
         <div> <center> <p>
           <Link to={{ pathname:"https://www.hydrangeagallery.com"}} target="Hydrangea Gallery">  Hydrangea Gallery (click for redirect) </Link> 
         </p> </center> </div>
        </div>
        <h5>{'Upcoming Shows'}</h5>
        <ul>
          {upcomingShows.length ? (
            upcomingShows.map(s => <ShowItem key={s.eventId} show={s} />)
          ) : (
            <p>no upcoming shows. check back shortly!</p>
          )}
        </ul>
        <h5>{currentShows.length ? 'Current Shows' : ''}</h5>
        <ul>{currentShows.length ? currentShows.map(s => <ShowItem key={s.eventId} show={s} />) : ''}</ul>
        
        <h5>About Maryann</h5>
        <div className='about-section'>
          <div className='bio-section'>
            <p className='bio'>
              Maryann Hondo lives and works in Arlington, Mass. Her work reflects the power of light
              and color&mdash;two dimensionally in Plein Air Watercolors and three dimensionally in
              her blown glass.
            </p>
            <p className='bio'>
              Her Plein Air work represents travel adventures in Euope and NY as well as her home in
              Boston. She is primarily self taught but has studied painting and pastels at the MFA
              school, the De Cordova School and the Woodstock School of Art, Woodstock NY (under
              Staats Fasoldt).
            </p>
            <p className='bio'>
              Her work has been included in juried exhibits at the New England WaterColor Society (NEWS), the Green Mountain Watercolor Festival, De
              Cordova, Woodstock and the MFA school. She participates yearly in the Beacon Hill Art
              Walk in Boston.
            </p>
          </div>
        </div>
        <div className='profile-images'>
         <div className='picture-container' >
          <div className='image-container' >
            <img src={maryannPainting} className='hidden' alt='MH PleinAir'/>
            <p>En Plein Air</p>
          </div>
          <div className='image-container' >
            <img src={maryannGlass} className='hidden' alt='MH GlassStudio'/>
            <p>In the Glass Studio</p>
          </div>
         </div>
        </div>

        <div> <center>
          <h5>{pastShows.length ? 'Past Shows' : ''}</h5>
          <ul>{pastShows.length ? pastShows.map(s => <ShowItem key={s.eventId} show={s} />) : ''}</ul>
        </center> </div>  
      </div>
    </div>
  )
}
