import React from 'react'

function BeaconHill2022() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Beacon Hill Art Walk 2022</h3>
        <ul className='event-date'>
          <li> exhibition dates: June 4, 2022 </li>
        </ul>
      </div>
      <div className='picture-container'>
        <img src='../biopic/Triton.jpg' alt='' className={'img'} />
        <img src='../biopic/StillLife.jpg' alt='' className={'img'} />
        <img src='../biopic/VaseCane.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: BeaconHill2022,
  title: 'Beacon Hill 2022',
  StartDate: 'June 4, 2022',
  EndDate: 'June 4, 2022',
  eventId: 'beacon-hill-2022',
}

export default details
