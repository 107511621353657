import React from 'react'
import { Link } from 'react-router-dom'
import artList from '../Assets/artList.json'
import { generateSrcSet } from './Art'
import './DetailPage.css'
import EmailLink from './EmailLink'

export default function DetailPage(props) {
  const { match } = props
  const { title } = match.params

  const art = artList.find(i => i.title === title)

  const category = art.category
  const images = Object.values(art.images).sort(({ width: w1 }, { width: w2 }) => w1 - w2)
  const srcSet = generateSrcSet(images)

  return (
    <div className='details-page'>
      <div className='single-wrapper'>
        <Link to={`/${category}`} className='bread-crumb'>
          back
        </Link>
        <div className='image-wrapper'>
          <img srcSet={srcSet} data-sizes='auto' alt={art.title} />
        </div>
        <div className='information'>
          <div className='title'>
            <strong>{title}</strong>
          </div>
          <div className='mediatype'>{art.attributes}</div>
          <div className='purchase'>
            <em>
              <EmailLink art={art}>Email</EmailLink> to purchase
            </em>
          </div>
        </div>
      </div>
    </div>
  )
}
