import React from 'react'

function SeasonsOfLight2023() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Seasons OF Light 2023</h3>
        <ul className='event-date'>
          <li> June 24 - August 26, 2023 </li>
          <li className='hours'> Hours: Tuesday and Saturday 10am-4pm </li>
          <li> Opening Reception: June 24, 2:30-4pm </li>
          <li> Closing Reception: August 26, 2-3:30pm </li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../../biopic/SeasonsOfLightCard_crop.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: SeasonsOfLight2023,
  title: 'Season of Light',
  StartDate: 'June 24, 2023',
  EndDate: 'August 26, 2023',
  eventId: 'seasons-of-light-2023',
}

export default details
