import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'
import './Components/Carousel/Carousel.css'
import { Home } from './TopLevelPages/Home.js'
import { Category } from './TopLevelPages/Category'
import { Contact } from './TopLevelPages/Contact'
import DetailPage from './Components/DetailPage'
import ArtShowEventPage from './TopLevelPages/ArtShowEventPage'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className='App'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/detail/:title' component={DetailPage} />
            <Route path='/shows/:eventId' component={ArtShowEventPage} />
            <Route path='/:category' component={Category} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default App
