import React from 'react'
import NavigationThumbnail from './NavigationThumbnail'
import './ThumbnailNav.css'

const bowlImage = require('../Assets/corningBowl.jpg')
const cityscapeImage = require('../Assets/PiazzaFontana-LR.jpg')
const figureImage = require('../Assets/female.jpg')
const landscapesImage = require('../Assets/AutumnMass.jpg')
const structuralGlassImage = require('../Assets/smallRed&GoldPumpkin.jpg')
const stillLivesImage = require('../Assets/3pearsWC.jpg')

const images = [
  {
    title: 'Landscapes',
    imageURL: landscapesImage,
    category: 'painting',
    attribute: 'landscape',
  },
  {
    title: 'The Figure',
    imageURL: figureImage,
    category: 'figure',
    attribute: 'male',
  },
  {
    title: 'Functional Glass',
    imageURL: bowlImage,
    category: 'glass',
    attribute: 'functional',
  },
  {
    title: 'Cityscape',
    imageURL: cityscapeImage,
    category: 'painting',
    attribute: 'cityscape',
  },
  {
    title: 'Still Lives',
    imageURL: stillLivesImage,
    category: 'painting',
    attribute: 'still life',
  },
  {
    title: 'Structural Glass',
    imageURL: structuralGlassImage,
    category: 'glass',
    attribute: 'structural',
  },
]

export default function ThumbnailContainer() {
  return (
    <div className='thumbnail-container'>
      <div className={'thumbnail-row'}>
        <NavigationThumbnail
          title={images[0].title}
          image={images[0].imageURL}
          category={images[0].category}
          attributes={images[0].attribute}
        />
        <NavigationThumbnail
          title={images[1].title}
          image={images[1].imageURL}
          category={images[1].category}
          attributes={images[1].attribute}
        />
        <NavigationThumbnail
          title={images[2].title}
          image={images[2].imageURL}
          category={images[2].category}
          attributes={images[2].attribute}
        />
      </div>
      <div className={'thumbnail-row'}>
        <NavigationThumbnail
          title={images[3].title}
          image={images[3].imageURL}
          category={images[3].category}
          attributes={images[3].attribute}
        />
        <NavigationThumbnail
          title={images[4].title}
          image={images[4].imageURL}
          category={images[4].category}
          attributes={images[4].attribute}
        />
        <NavigationThumbnail
          title={images[5].title}
          image={images[5].imageURL}
          category={images[5].category}
          attributes={images[5].attribute}
        />
      </div>
    </div>
  )
}
