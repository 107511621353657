import React from 'react'
import { Link } from 'react-router-dom'
import { TopicsMenu } from './TopicsMenu'
import './../App.css'
import './Nav.css'

export const Nav = props => {
  return (
    <nav>
      <ul className='nav-top-menu'>
        <li className='top-menu-item'>
          <TopicsMenu
            title='watercolor'
            category='painting'
            attributes={['still life', 'landscape', 'cityscape']}
          />
        </li>
        <li className='top-menu-item'>
          <TopicsMenu title='glass' category='glass' attributes={['structural', 'functional']} />
        </li>
        <li className='main-title top-menu-item'>
          <Link to='/'>
            {' '}
            <h1>Maryann&nbsp;Hondo</h1>{' '}
          </Link>
        </li>
        <li className='top-menu-item'>
          <TopicsMenu title='figure' category='figure' attributes={['male', 'female']} />
        </li>
        <li className='top-menu-item'>
          <div className='topic-menu main-header'>
            <Link to='/contact' className='nav-topic menu-header'>
              Contact
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  )
}
