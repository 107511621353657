import React from 'react'

function NEWS2022() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>New England Watercolor Society: Brushes with Nature</h3>
        <ul className='event-date'>
          <li> exhibition dates: November 5, 2021 - January 5, 2022 </li>
          <li> Award of Distinction </li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/Sedona3.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: NEWS2022,
  title: 'New England Watercolor Society: Brushes with Nature',
  StartDate: 'Jan 5, 2022',
  EndDate: 'Jan 5, 2022',
  eventId: 'NEWS2022',
}

export default details
