import React from 'react'

function Chatham2023() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>52ND ANNUAL FESTIVAL OF THE ARTS</h3>
        <ul className='event-date'>
          <li>exhibition dates: August 18, 19 & 20, 2023</li>
          <li>
            <a href='https://www.capecodcreativearts.org/events-1/2022/8/19/51st-annual-festival-of-the-arts-na4wr'>
              www.capecodcreativearts.org
            </a>
          </li>
          <li>Chase Park, Chatham, MA</li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/chatham2023/TentPicture-Small.jpg' alt='' className='img' />
        <img src='../biopic/chatham2023/waterfall2-Small.jpg' alt='' className='img' />
        <img src='../biopic/chatham2023/ChathamLighthouse-Small.jpg' alt='' className='img' />
        <img src='../biopic/chatham2023/venice2-Small.jpg' alt='' className='img' />
        <img src='../biopic/chatham2023/BlueRings-Small.jpg' alt='' className='img' />
      </div>
    </div>
  )
}

const details = {
  component: Chatham2023,
  title: '52nd Annual Festival Of The Arts',
  StartDate: 'August 18, 2023',
  EndDate: 'August 20, 2023',
  eventId: '52nd-annual-festival-of-the-arts',
}

export default details
