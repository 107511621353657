import React, { useEffect, useMemo, useRef, useState } from 'react'
import ImageFrame from './ImageFrame.js'
import lodash from 'lodash'

export default function Gallery({ category, attributes, artList: fullArtList }) {
  const artList = useArtList(category, attributes, fullArtList)
  const columns = useResponsiveColumns(artList)
  return (
    <div key={`${category}-${attributes}`} className='image-row-meta-container'>
      {columns.map((art, i) => (
        <div className='image-row-container' key={i}>
          {art.map((pic, i) => {
            return <ImageFrame key={i} image={pic} />
          })}
        </div>
      ))}
    </div>
  )
}

function useArtList(category, attributes, fullArtList) {
  const artList = useMemo(() => {
    // Check if there is an art list in session store, and return it if so.
    const artListKey = `${category}-${attributes}-artList`
    try {
      const existingArtList = JSON.parse(sessionStorage.getItem(artListKey))
      if (existingArtList) return existingArtList
    } catch (e) {
      console.error('Error loading art list', e)
    }

    // Otherwise, compute the art list and store it in session store.
    const artList = randomizeArtList(sortArtList(fullArtList, category, attributes))
    try {
      sessionStorage.setItem(artListKey, JSON.stringify(artList))
    } catch (e) {
      console.error('Error saving art list', e)
    }
    return artList
  }, [category, attributes, fullArtList])

  return artList
}

function randomizeArtList(artList) {
  let curId = artList.length
  // There remain elements to shuffle
  while (0 !== curId) {
    // Pick a remaining element
    let randId = Math.floor(Math.random() * curId)
    curId -= 1
    // Swap it with the current element.
    let tmp = artList[curId]
    artList[curId] = artList[randId]
    artList[randId] = tmp
  }
  return artList
}

function sortArtList(artList, category, attributes) {
  return artList
    .filter(pic => (category && pic.category === category) || !category)
    .filter(
      pic =>
        (attributes && pic.attributes.toLocaleLowerCase() === attributes.toLocaleLowerCase()) ||
        !attributes
    )
    .sort(artByTitleAlphabetically)
}

function useResponsiveColumns(art) {
  const condition = useRef(matchMedia('(max-width: 600px)'))
  const chooseNCols = () => (condition.current.matches ? 1 : 3)
  const [nCol, setNCol] = useState(chooseNCols)

  useEffect(() => {
    function listener() {
      setNCol(chooseNCols())
    }
    const refCurrent = condition.current
    refCurrent.addEventListener('change', listener)
    return () => {
      refCurrent.removeEventListener('change', listener)
    }
  }, [])

  return splitIntoEvenColumns(nCol, art)
}

/**
 * Build roughly-even columns by adding each piece to the currently-shortest
 * column.
 */
function splitIntoEvenColumns(nCol, art) {
  const artHeight = item => lodash.min(Object.values(item.images).map(i => i.height))

  const columns = new Array(nCol).fill().map(() => ({ items: [], height: 0 }))
  for (const item of art) {
    const shortestColumn = lodash.minBy(columns, c => c.height)
    shortestColumn.items.push(item)
    shortestColumn.height += artHeight(item)
  }

  return columns.map(c => c.items)
}

function artByTitleAlphabetically(i1, i2) {
  return i1.title.localeCompare(i2.title, 'en', { sensitivity: 'base' })
}
