import React from 'react'

function BostonArts2022() {
  return (
    <div className='pageContainer'>
      <div className='event-header'>
        <h3 className='event-title'>Boston Arts Festival</h3>
        <ul className='event-date'>
          <li> exhibition dates: september 10, 2022 </li>
        </ul>
      </div>
      <div className='image-container'>
        <img src='../biopic/Charles2.jpg' alt='' className={'img'} />
        <img src='../biopic/figure5.jpg' alt='' className={'img'} />
        <img src='../biopic/VaseCane.jpg' alt='' className={'img'} />
      </div>
    </div>
  )
}

const details = {
  component: BostonArts2022,
  title: 'Boston Arts Festival',
  StartDate: 'September 10, 2022',
  EndDate: 'September 10, 2022',
  eventId: 'boston-arts-2022',
}

export default details
